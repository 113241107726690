import Title from "../atoms/Title"
import Text from "../atoms/Text"
import moment from "moment";
import Link from "next/link";
import Button from "../atoms/Button";
import calendarIcon from "@/images/icons/calendar-happenings.svg";
import Image from "next/image";
import { useTranslation } from "next-i18next";

export default function HappeningCard({
    title = "", locale = "", dateTimeRange = {}, subtitle = "", zone = "", happeningIn = false, slug = null, happeningsUrl = "", calendarText = "" }) {

    const defaultStyles = {
        dayCalendar: "hero-text text-[14px] leading-[20px] text-center text-[#FFFFFF] pt-2",
        numberCalendar: "hero-text text-[32px] leading-[20px] text-center text-[#FFFFFF] pt-2",
        monthCalendar: "hero-text text-[14px] leading-[20px] text-center text-[#FFFFFF] pt-2",
        title: "hero-text md:text-[16px] text-[14px] leading-[22.4px] text-main-blue font-medium",
        subtitle: "font-subtitle md:text-[16px] text-[14px] md:leading-[30px] leading-[28px] text-main-orange",
        date: "font-title md:text-[16px] text-[12px] md:leading-[21px] leading-[28px] text-main-grey font-thin",
        zone: "font-title md:text-[16px] text-[12px] md:leading-[21px] text-main-grey font-thin",
        calendar: "hero-link leading-[20px] text-left text-main-blue-king",
    }
    const date = moment(dateTimeRange?.start);
    const dateDay = date?.locale(locale)?.format("dddd");
    let de = "de";
    if (locale !== "es") {
        de = "of";
    }
    const dateEnd = moment(dateTimeRange?.end);
    const startDateUTC = new Date(dateTimeRange?.start)?.toISOString()?.replace(/-|:|\.\d+/g, "");
    const endDateUTC = new Date(dateTimeRange?.end)?.toISOString()?.replace(/-|:|\.\d+/g, "");
    const googleCalendarUrl = `https://calendar.google.com/calendar/u/0/r/eventedit?dates=${startDateUTC}/${endDateUTC}&text=${encodeURIComponent(title)}&location=${encodeURIComponent("Golden Parnassus All Inclusive Resort & Spa, Km. 14.5, Blvd. Kukulcan, Zona Hotelera, 77500 Cancún, Q.R., Mexico")}`;
   
    const { t } = useTranslation("common");

    return (
        <div>
            <div className="hidden md:block">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-2 lg:px-3">
                        <div className="w-full h-[90px] bg-main-blue ">
                            <Text className={defaultStyles?.dayCalendar}>
                                {dateDay?.charAt(0)?.toUpperCase() + dateDay?.slice(1)}
                            </Text>
                            <Text className={defaultStyles?.numberCalendar}>
                                {date?.date()}
                            </Text>
                            <Text className={defaultStyles?.monthCalendar}>
                                {date?.format('MMM')?.charAt(0)?.toUpperCase() + date?.format('MMM')?.slice(1)?.replace(/\./, '')}
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-8 ">
                        {!happeningIn &&
                            <Title className={defaultStyles?.title} tag="h3">
                                {title}
                            </Title>
                        }
                        {
                            happeningIn &&
                            <div>
                                <Link href={`${happeningsUrl}/${slug}`}>
                                    <Title className={defaultStyles?.title} tag="h3">
                                        {title}
                                    </Title>
                                </Link>
                            </div>
                        }
                        <Text className={defaultStyles?.subtitle}>
                            {subtitle}
                        </Text>
                        <Text className={defaultStyles?.date}>
                            {date?.format(`dddd D [${de}] MMMM, YYYY`)}
                        </Text>
                        <div className="lg:flex align-items-left">
                            <Text className={defaultStyles?.zone + " lg:mr-1"}>
                                {`${date?.format('HH:mm')} - ${dateEnd?.format('HH:mm')}hrs`}
                            </Text>
                            <Text className={defaultStyles?.zone + " md:hidden lg:block"}> | </Text>
                            <Text className={defaultStyles?.zone + " lg:ml-1"}>
                                {`${zone}`}
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-2  flex ">
                        <div className="mt-2 mr-1">
                            <Image
                                src={calendarIcon?.src}
                                height={calendarIcon?.height}
                                width={calendarIcon?.width}
                                alt={t("common:happenings_images.calendar.alt")}
                            />
                        </div>
                        <Link href={googleCalendarUrl || "/"} target="_blank">
                            <Button className={defaultStyles?.calendar}>
                                {calendarText}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="block md:hidden">
                <div className="grid grid-cols-12 gap-1">
                    <div className="col-span-3 bg-main-blue-king h-[95px]">
                        <Text className={defaultStyles?.dayCalendar}>
                            {dateDay?.charAt(0)?.toUpperCase() + dateDay?.slice(1)}
                        </Text>
                        <Text className={defaultStyles?.numberCalendar}>
                            {date?.date()}
                        </Text>
                        <Text className={defaultStyles?.monthCalendar}>
                            {date?.format('MMM')?.charAt(0)?.toUpperCase() + date?.format('MMM')?.slice(1)?.replace(/\./, '')}
                        </Text>
                    </div>
                    <div className="col-span-9 ml-2">
                        {!happeningIn &&
                            <Title className={defaultStyles?.title} tag="h3">
                                {title}
                            </Title>
                        }
                        {
                            happeningIn &&
                            <div>
                                <Link href={`${happeningsUrl}/${slug}`}>
                                    <Title className={defaultStyles?.title} tag="h3">
                                        {title}
                                    </Title>
                                </Link>
                            </div>
                        }
                        <Text className={defaultStyles?.subtitle}>
                            {subtitle}
                        </Text>
                        <Text className={defaultStyles?.date}>
                            {date?.format(`dddd D [${de}] MMMM, YYYY`)}
                        </Text>
                        <Text className={defaultStyles?.zone}>
                            {`${date?.format('HH:mm')} - ${dateEnd?.format('HH:mm')}hrs | ${zone}`}
                        </Text>
                        <div className="flex mt-4">
                            <div className="mt-2 mr-1">
                                <Image
                                    src={calendarIcon?.src}
                                    height={calendarIcon?.height}
                                    width={calendarIcon?.width}
                                    alt={t("common:happenings_images.calendar.alt")}
                                />
                            </div>
                            <Link href={googleCalendarUrl || "/"} target="_blank">
                                <Button className={defaultStyles?.calendar}>
                                    {calendarText}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
