import { apiUrl, getbookingUrl, roibackUrl } from "@/utils/api";
import classNames from "classnames";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import Text from "../atoms/Text";
import Title from "../atoms/Title";

import capacity from "@/images/icons/capacity.svg";
import beds from "@/images/icons/beds-grey.svg";
import area from "@/images/icons/area-grey.svg";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import DropdownTabs from "../molecules/DropdownTabs";
import { useTranslation } from "next-i18next";

const RoomTab = ({ rooms = [], categories = [], copy = {} }) => {
  const { t } = useTranslation(["common", "routes"]);
  const [filter, setFilter] = useState("");
  const [room, setRoom] = useState({});
  const [active, setActive] = useState(0);
  const [roomSliders, setRoomSliders] = useState([]);

  const onSelect = (key) => {
    setActive(key);
    setRoom(
      rooms.filter((roomItem) => roomItem?.room_category?.id === filter)[key]
    );
  };

  useEffect(() => {
    if (Array.isArray(categories) && categories.length) {
      setFilter(categories[0].id);
    }
  }, [categories]);

  useEffect(() => {
    if (Array.isArray(rooms)) {
      setRoom(rooms.find((room) => room?.room_category?.id === filter) || {});
    }
  }, [filter, rooms]);

  useEffect(() => {
    if (
      Array.isArray(room.room_sliders) &&
      room.room_sliders.length &&
      room.locale === "es"
    ) {
      setRoomSliders(room.room_sliders);
    }
    if (
      room.locale !== "es" &&
      room.localizations &&
      room.localizations.length
    ) {
      setRoomSliders(room.localizations[0].room_sliders);
    }
  }, [room]);

  const gtmBookId = room?.locale === "es" && `room-${room?.slug}` || room?.locale === "en" && `room-${room?.localizations[0]?.slug}`
  const gtmMoreId = room?.locale === "es" && `more-${room?.slug}` || room?.locale === "en" && `more-${room?.localizations[0]?.slug}`

  return (
    <div className="bg-main-blue-light py-12">
      <div className="base-container base-grid">
        <div className="col-span-full md:col-span-10 md:col-start-2">
          <Text className="section-subtitle text-center md:text-left uppercase">
            {copy?.subtitle}
          </Text>
          <Title
            tag="h2"
            className="section-title block pt-4 normal-case text-center md:text-left tracking-normal md:text-5xl text-4xl pb-8"
          >
            {copy?.title}
          </Title>
          <Text className="hero-text text-center md:text-left mb-8">
            {copy?.text}
          </Text>
        </div>
        {Array.isArray(categories) && categories.length && (
          <div className=" col-span-12 flex justify-center">
            {categories.map((category) => (
              <div
                key={category.id}
                className={classNames("mx-2 sm:mx-8 py-2 px-2 sm:px-8 cursor-pointer flex flex-column items-center rounded-full", {
                  "bg-medium-grey": category.id !== filter,
                  "bg-main-blue": category.id === filter,
                })}
                onClick={() => setFilter(category.id)}
              >
                {category.id === filter && (
                  <Image
                    className=" mb-4"
                    src={apiUrl + (category?.icon_active?.url || "")}
                    width={category?.icon_active?.width || 30}
                    height={category?.icon_active?.height || 30}
                    alt={"icon_active"}
                  />
                )}
                {category.id !== filter && (
                  <Image
                    className=" mb-4"
                    src={apiUrl + (category?.icon_inactive?.url || "")}
                    width={category?.icon_inactive?.width || 30}
                    height={category?.icon_inactive?.height || 30}
                    alt={"icon_inactive"}
                  />
                )}
                <p
                  className={classNames("font-subtitle font-medium mx-2 uppercase text-lg", {
                    "text-main-blue": category.id !== filter,
                    "text-[#ffff]": category.id === filter,
                  })}
                >
                  {category.name}
                </p>
              </div>
            ))}
          </div>
        )}
        {filter && room.id && (
          <>
            <div className="hidden md:flex col-start-2 col-span-2 border-r border-main-blue  flex-col justify-around">
              {Array.isArray(rooms) &&
                rooms
                  .filter((roomItem) => roomItem?.room_category?.id === filter)
                  .map((roomItem) => (
                    <div
                      key={roomItem.id}
                      className={classNames("cursor-pointer uppercase pr-2", {
                        "font-subtitle font-medium text-main-blue":
                          roomItem.id === room.id,
                        "font-subtitle text-main-grey": roomItem.id !== room.id,
                      })}
                      onClick={() => setRoom(roomItem)}
                    >
                      {roomItem.name}
                    </div>
                  ))}
            </div>
            <div className="md:hidden col-span-12">
              <DropdownTabs
                tabs={rooms
                  .filter((roomItem) => roomItem?.room_category?.id === filter)
                  .map((roomItem) => {
                    return { id: roomItem.id, tab: roomItem.name };
                  })}
                onSelect={onSelect}
                active={active}
              />
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-9">
              <div className="base-grid">
                <div className="col-span-12 lg:col-span-6">
                  <Swiper
                    navigation={true}
                    modules={[Navigation]}
                    style={{
                      "--swiper-navigation-color": "#fff",
                      "--swiper-navigation-size": "20px",
                    }}
                  >
                    {Array.isArray(roomSliders) &&
                      roomSliders.map((slider) => (
                        <SwiperSlide key={slider.id}>
                          <div className="relative min-h-[300px]">
                            <Image
                              src={apiUrl + slider.image.url}
                              fill
                              className="object-cover"
                              alt="slider-image"
                            ></Image>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="col-span-12 lg:col-span-6">
                  <h3 className="hero-title text-3xl md:text-left mb-5">
                    {room.name}
                  </h3>
                  <div className="w-full flex justify-around md:justify-between mb-5">
                    <div>
                      <Image
                        src={capacity}
                        alt="icon-capacity"
                        className="md:inline-block mx-auto md:mr-2"
                      />
                      <span className="text-xs font-subtitle text-main-grey">
                        {room.capacity}
                      </span>
                    </div>
                    <div>
                      <Image
                        src={beds}
                        alt="icon-capacity"
                        className="md:inline-block mx-auto md:mr-2"
                      />
                      <span className="text-xs font-subtitle text-main-grey">
                        {room.beds}
                      </span>
                    </div>
                    <div>
                      <Image
                        src={area}
                        alt="icon-capacity"
                        className="md:inline-block mx-auto md:mr-2"
                      />
                      <span className="text-xs font-subtitle text-main-grey">
                        {room.area}
                      </span>
                    </div>
                  </div>
                  <p className="hero-text text-center md:text-left mb-5">
                    {room.short_description}
                  </p>
                  <div className="flex md:block">
                    <Link
                      className="base-button hero-link text-white no-underline inline-block uppercase"
                      href={getbookingUrl(room.locale, room.code)}
                      target="_blank"
                      id={gtmBookId}
                    >
                      {copy?.booking_now}
                    </Link>
                    <Link
                      className="hero-link px-11 py-3 uppercase"
                      href={{
                        pathname: `${t("routes:/rooms")}/[slug]`,
                        query: { slug: room.slug },
                      }}
                      id={gtmMoreId}
                    >
                      {copy?.see_details}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RoomTab;
