import classNames from "classnames";
import Paragraphs from "./Paragraphs";
import markicon from "@/public/images/icons/quotation-marks.svg";
import Image from "next/image";
import Text from "../atoms/Text";
import Link from "next/link";
const ReviewCard = (props) => {
  const styles = {
    cardContainer:
      props?.classes?.cardContainer || "base-grid pt-8 md:pt-12 px-3",
    card:
      props?.classes?.card ||
      "col-span-12 md:col-span-10 md:col-start-2 bg-main-blue-light base-grid",
    paragraphsContainer:
      props?.classes?.paragraphsContainer || "col-span-10 col-start-2 py-12",
    byContainer:
      props?.classes?.byContainer ||
      "col-span-4 col-start-5 text-center pt-6 md:pt-12",
    by: props?.classes?.by || "font-subtitle text-base text-[#404040]",
  };
  const paragraphs =
    props.paragraphs && Array.isArray(props.paragraphs)
      ? props?.paragraphs.map((p) => {
          return {
            ...p,
            classes:
            p?.classes || "hero-text my-4 text-sm md:text-base text-center leading-[28px] md:leading-[32px]",
          };
        })
      : false;
  return (
    <div className={classNames(styles?.cardContainer, "relative")}>
      <div className="w-16 h-16 md:w-24 md:h-24 absolute left-0 right-0 m-auto bg-white flex justify-center items-center rounded-full top-0 z-10">
        <Image src={markicon} alt="mar-icon" className="w-1/2" />
      </div>
      <div className={classNames(styles?.card, "relative")}>
        {paragraphs && (
          <Paragraphs
            items={paragraphs}
            classes={styles?.paragraphsContainer}
          />
        )}
        <div className="h-px w-6  sm:w-10 md:w-20 bg-main-blue-king absolute top-0 bottom-0 m-auto -left-3 md:-left-12" />
        <div className="h-px w-6 sm:w-10 md:w-20 bg-main-blue-king absolute top-0 bottom-0 m-auto -right-3 md:-right-12" />
        <div className="w-px h-10 md:h-20 bg-main-blue-king absolute left-0 right-0 m-auto -bottom-5 md:-bottom-12" />
      </div>
      <div className={classNames(styles?.byContainer)}>
      <Link href={props?.link || "/"} target="_blank">
        <Text className={classNames(styles?.by)}>{props?.by || ""}</Text>{" "}
        </Link>
      </div>
    </div>
  );
};
export default ReviewCard;
