
import Title from '@/components/atoms/Title'
import Text from "../atoms/Text";
import DropdownTabs from '@/components/molecules/DropdownTabs'
import moment from 'moment-timezone';
import 'moment/locale/es';
import { useState, useEffect } from 'react';
import HappeningCard from '@/components/organisms/HappeningCard';
import Image from 'next/image';
import happeningsImage from "@/images/common/activities.webp"
import Button from '@/components/atoms/Button';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function HappeningsList(
    { locale = "", happenings = [], happeningsUrl = "", titleSection = "", text = "", calendarText = "", moreHappeningsText = "" }) {
    happenings = happenings?.map((happening) => {
        return {
            ...happening,
            start_time: moment?.utc(happening?.start_time)?.tz("America/Mexico_City")?.format('YYYY-MM-DDTHH:mm:ss'),
            end_time: moment?.utc(happening?.end_time)?.tz("America/Mexico_City")?.format('YYYY-MM-DDTHH:mm:ss')
        }
    })
    happenings.sort((a, b) => {
        const dateA = new Date(a?.start_time);
        const dateB = new Date(b?.end_time);
        return dateA - dateB;
    });
    const monthsHappenings = [];
    for (let i = 0; i < happenings.length; i++) {
        let date = moment(happenings[i]?.start_time);
        let nameMonth = date?.locale(locale)?.format("MMMM")?.toUpperCase();
        let findMonth = monthsHappenings.find(month => month?.tab === nameMonth);
        if (!findMonth) {
            monthsHappenings.push({
                tab: nameMonth,
                happenings: [happenings[i]]
            });
        } else {
            findMonth?.happenings.push(happenings[i])
        }
    }

    const [active, setActive] = useState(0);
    const [happenigsInTheMonth, setHappenigsInTheMonth] = useState(monthsHappenings[0]);
    const onSelect = (key) => {

        setActive(key);
        setHappenigsInTheMonth(monthsHappenings[key]);
    };

    const [topImage, setTopImage] = useState("");

    useEffect(() => {
        switch (happenigsInTheMonth?.happenings?.length) {
            case 1:
                setTopImage("-270")
                break;

            case 2:
                setTopImage("-360")
                break;
            default:
                setTopImage("-450")
                break;
        }
    }, [happenigsInTheMonth])
    const defaultStyles = {
        title: "font-title text-main-blue md:text-[50px] md:leading-[55px] text-center text-[30px] leading-[40px]",
        containerAll: "relative",
        dropDownStyles: {
            dropdownContainer: "md:w-[258px] min-w-[50%] md:min-w-[10px] md:h-[40px] w-fit mx-auto relative"
        },
        divCard: "mb-10 ",
        containerCards: " bg-main-blue-light col-span-10 grid grid-cols-12 lg:max-h-[536px] md:max-h-[600px] ",
        containerCardsSecond: "md:mt-16 md:ml-6 col-span-9 overflow-auto",
        containerImage: "col-span-2 relative",
        divImage: `absolute  max-h-10`,
        moreEvents: "hero-link md:leading-[14px] text-center",
        divImageMobile: "flex justify-center  absolute top-[-140px] w-full",
        containerCardsMobile: "bg-main-blue-light mx-1 max-h-[786px]",
        containerCardsSecondMobile: "mt-[200px] mx-3 overflow-auto max-h-[582px]",
    };
    const happeningsUrlSection = happeningsUrl + "#happenings"
    useEffect(()=>{
        setHappenigsInTheMonth(monthsHappenings[active])
    },[locale])
    const textt = text
    ? {
        text: text?.text || "",
        className:
          text?.className || "section-subtitle text-center uppercase",
      }
    : false;
    const { t } = useTranslation("common");

    return (
        <div className='container mx-auto '>
            <div className='mt-20'>
                <Title className={defaultStyles?.title} tag="h2">
                    {titleSection}
                </Title>
            </div>
            <div className={defaultStyles?.containerAll}>
                <div className='md:mt-10 mt-5 mb-16'>
                    <DropdownTabs
                        tabs={monthsHappenings}
                        onSelect={onSelect}
                        active={active}
                        classes={defaultStyles?.dropDownStyles}
                    />
                </div>
                <div className='hidden md:block'>
                    <div className='grid grid-cols-12 relative '>
                        <div className={defaultStyles?.containerCards}>
                            <div className={defaultStyles?.containerCardsSecond}>
                                {
                                    happenigsInTheMonth?.happenings?.map((happening, key) => (
                                        <div key={key} className={defaultStyles?.divCard}>
                                            <HappeningCard
                                                title={happening?.title}
                                                locale={locale}
                                                dateTimeRange={{
                                                    start: happening?.start_time,
                                                    end: happening?.end_time,
                                                }}
                                                subtitle={happening?.subtitle}
                                                zone={happening?.zone}
                                                happeningIn={happening?.happeningIn}
                                                slug={happening?.slug}
                                                happeningsUrl={happeningsUrl}
                                                calendarText={calendarText}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='block md:hidden relative mt-[230px]'>
                    <div className={defaultStyles?.divImageMobile}>
                        <Image
                            src={happeningsImage?.src}
                            width={272}
                            height={272}
                            alt={t('common:happenings_images.main_image.mobile.alt')}
                        />
                    </div>
                    <div className={defaultStyles?.containerCardsMobile}>
                        <div className={defaultStyles?.containerCardsSecondMobile}>
                            {
                                happenigsInTheMonth?.happenings?.map((happening, key) => (
                                    <div key={key} className={defaultStyles?.divCard}>
                                        <HappeningCard
                                            title={happening?.title}
                                            locale={locale}
                                            dateTimeRange={{
                                                start: happening?.start_time,
                                                end: happening?.end_time,
                                            }}
                                            subtitle={happening?.subtitle}
                                            zone={happening?.zone}
                                            happeningIn={happening?.happeningIn}
                                            slug={happening?.slug}
                                            happeningsUrl={happeningsUrl}
                                            calendarText={calendarText}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='grid grid-cols-12 relative'>
                <div className='relative col-span-4 col-start-9'>
                    <div className={defaultStyles?.divImage} style={{ top: `${topImage}px` }}>
                        <div className='max-h-[325px] max-w-[325px] hidden md:block'>
                            <Image
                                src={happeningsImage?.src}
                                width={happeningsImage?.width}
                                height={happeningsImage?.height}
                                alt={t('common:happenings_images.main_image.desktop.alt')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center md:mt-16 mt-8 mb-10'>
                <Link href={happeningsUrlSection || "/"}>
                    <Button className={defaultStyles?.moreEvents}>
                        {moreHappeningsText}
                    </Button>
                </Link>
            </div>
        </div>
    )
}