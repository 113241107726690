import Title from "@/components/atoms/Title";
import Text from "../atoms/Text";
import reviewsicon from "@/public/images/icons/reviews-icon.svg";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import ReviewCard from "../molecules/ReviewCard";

const Reviews = (props) => {
  const settings = props?.settings || {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: {
      delay: 10000
    },
    speed: 1500
  };
  const title = props.title
    ? {
        tag: props.title?.tag || "h2",
        text: props.title?.text || "",
        className:
          props.title?.className ||
          "hero-title  text-3xl md:text-5xl md:text-center",
      }
    : false;
  const text = props.text
    ? {
        text: props.text?.text || "",
        className:
          props.text?.className || "section-subtitle text-center uppercase",
      }
    : false;

  const styles = {
    section: props.classes?.section || " py-12",
    container: props.classes?.container || "base-container base-grid px-0",
    titleContainer:
      props?.classes?.titleContainer ||
      "col-span-12  lg:flex justify-center items-center gap-x-8",
    textContainer: props?.classes?.textContainer || "col-span-12",
    carouselContainer: props?.classes?.carouselContainer || "col-span-12",
  };
  const reviews =
    props.reviews && Array.isArray(props.reviews) ? props?.reviews : false;
  return (
    <section className={classNames(styles?.section)}>
      <div className={classNames(styles?.container)}>
        <div className={styles?.textContainer}>
          <Text className={text?.className}>{text?.text}</Text>
        </div>
        <div className={classNames(styles?.titleContainer)}>
          <Title tag={title.tag} className={title.className}>
            {title.text}
          </Title>
          <Link
            href={
              "https://www.tripadvisor.com.mx/Hotel_Review-g150807-d184989-Reviews-Golden_Parnassus_All_Inclusive_Resort_Spa_Cancun-Cancun_Yucatan_Peninsula.html"
            }
            target="_blank"
            className="flex justify-center items-center mt-10 lg:mt-0"
          >
            <Image src={reviewsicon} alt="icon-revies-golden" />
          </Link>
        </div>
        <div className={classNames(styles?.carouselContainer)}>
          <Swiper modules={[Autoplay]} {...settings}>
            {reviews &&
              reviews?.map((review, key) => {
                return (
                  <SwiperSlide key={key}>
                    <ReviewCard {...review} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default Reviews;
