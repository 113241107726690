import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import Title from "../atoms/Title";
import Text from "../atoms/Text";

import facebook from "@/images/icons/facebook-orange.svg";
import instagram from "@/images/icons/instagram-orange.svg";
import tripadvisor from "@/images/icons/trip-orange.svg";
import axios from "axios";

import insta01 from "@/images/home/instagram01.webp";
import insta02 from "@/images/home/instagram02.webp";
import insta03 from "@/images/home/instagram03.webp";
import insta04 from "@/images/home/instagram04.webp";

const DEFAUL_IMAGES = [
  {
    id: "17854132577857951",
    media_type: "IMAGE",
    media_url: insta01,
    className: "col-span-12 lg:col-span-3 relative min-h-[260px]  lg:min-h-[240px]"
  },
  {
    id: "17854132577857952",
    media_type: "IMAGE",
    media_url: insta02,
    className: "col-span-6 lg:col-span-3 relative min-h-[124px]  lg:min-h-[240px]"
  },
  {
    id: "17854132577857954",
    media_type: "IMAGE",
    media_url: insta03,
    className: "col-span-6 lg:col-span-3 relative min-h-[124px]  lg:min-h-[240px]"
  },
  {
    id: "17854132577857955",
    media_type: "IMAGE",
    media_url: insta04,
    className: "col-span-12 lg:col-span-3 relative min-h-[260px]  lg:min-h-[240px]"
  },
];

const Instagram = ({ images = DEFAUL_IMAGES, copy = {} }) => {
  const apiToken = process.env.INSTAGRAM_TOKEN;
  const [feeds, setFeedsData] = useState(images);
  const getInstagramImages = () => {
    try {
      axios
        .get(
          `https://graph.instagram.com/me/media?fields=media_type,media_url&limit=15&access_token=${apiToken}`
        )
        .then((resp) => {
          setFeedsData(
            resp.data.data.filter(
              (item) =>
                item.media_type === "IMAGE" ||
                item.media_type === "CAROUSEL_ALBUM"
            )
          );
        });
    } catch (err) {
      return err;
    }
  };
  useEffect(() => {
    if (apiToken) {
      getInstagramImages();
    }
  }, [apiToken]);
  return (
    <div className="base-container base-grid py-5 md:pt-20 md:pb-10">
      <div className=" col-span-12 md:col-start-2 md:col-span-10">
        <Text className="section-subtitle text-center uppercase text-[28px] tracking-[.2em]">
          {copy?.title1}
        </Text>
        <Title tag="h2" className="hero-title text-xl md:text-3xl lg:text-5xl">
          {copy?.title2}
        </Title>
      </div>
      <div className="col-span-12 md:col-span-6 text-center md:text-right md:py-10">
        <Link
          href={"https://www.facebook.com/GoldenParnassusCancun/"}
          target="_blank"
        >
          <Image
            src={facebook}
            width={55}
            height={55}
            alt="social-media"
            className="inline-block mr-4"
          />
        </Link>
        <Link
          href={"https://www.instagram.com/goldenparnassus/"}
          target="_blank"
        >
          <Image
            src={instagram}
            width={55}
            height={55}
            alt="social-media"
            className="inline-block mr-4"
          />
        </Link>
        <Link
          href={
            "https://www.tripadvisor.com.mx/Hotel_Review-g150807-d184989-Reviews-Golden_Parnassus_All_Inclusive_Resort_Spa_Cancun-Cancun_Yucatan_Peninsula.html"
          }
          target="_blank"
        >
          <Image
            src={tripadvisor}
            width={55}
            height={55}
            alt="social-media"
            className="inline-block"
          />
        </Link>
      </div>
      <div className="col-span-12 md:col-span-6 text-center md:text-left md:py-10 md:flex items-center">
        <Link
          href={"https://www.instagram.com/goldenparnassus/"}
          target="_blank"
          className="hero-link no-underline text-3xl font-normal"
        >
          @goldenparnassus
        </Link>
      </div>
      {Array.isArray(feeds) &&
        feeds.slice(0, 4).map((feed, index) => (
          <div
            key={feed.id}
            className={DEFAUL_IMAGES[index]?.className}
          >
            <Image
              src={feed?.media_url?.src || feed?.media_url}
              width={feed?.media_url?.width || 783}
              height={feed?.media_url?.height || 783}
              className="w-full h-full object-cover"
              alt={`instagran-image-${index + 1}`}
            />
          </div>
        ))}
    </div>
  );
};

export default Instagram;
