import Tabs from "@/components/templates/Tabs";
import { useState } from "react";
import TabItems from "@/components/molecules/TabItems";
import classNames from "classnames";
const TabsWithFilter = (props) => {
  const filters =
    props?.filters && Array.isArray(props?.filters) ? props?.filters : false;
  const [active, setActive] = useState(0);
  const onSelect = (key) => {
    setActive(key);
  };
  const styles = {
    section: props?.classes?.section || "py-12",
    container: props?.classes?.container || "base-container pb-6",
    filters: props?.classes?.filters || {
      active: " text-main-blue font-semibold  md:mx-3",
      inactive: " text-medium-grey  hover:text-main-blue-king  md:mx-3 ",
      tab: "font-subtitle text-lg uppercase px-3 ",
    },
  };
  return (
    <section className={classNames(styles?.section)}>
      <div className={classNames(styles?.container)}>
        <TabItems
          tabs={filters}
          onSelect={onSelect}
          classes={styles?.filters}
          active={active}
        />
      </div>
      <Tabs tabs={filters[active]?.tabs || []} classes={props?.classes}/>
    </section>
  );
};

export default TabsWithFilter;
