import Image from "next/image";
import { Swiper, SwiperSlide } from "swiper/react";

import slide1 from "@/images/slideshow/13-carrusel-galeria-01.webp";
import slide2 from "@/images/slideshow/13-carrusel-galeria-02.webp";
import slide3 from "@/images/slideshow/13-carrusel-galeria-03.webp";
import slide4 from "@/images/slideshow/13-carrusel-galeria-04.webp";
import slide5 from "@/images/slideshow/13-carrusel-galeria-05.webp";
import slide6 from "@/images/slideshow/13-carrusel-galeria-06.webp";
import { Navigation, Pagination } from "swiper";
import { useEffect, useState } from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";

const DEFAULT_IMAGES = [
  {
    alt: "alberca-con-camastros-con-vista-a-la-playa-de-cancun-con-atardecer",
    ...slide1
  },
  {
    alt: "vista-de-alberca-con-el-hotel-y-la-playa-de-fondo",
    ...slide2
  },
  {
    alt: "playa-de-cancun-de-dia-con-camastros-sombrillas",
    ...slide3
  },
  {
    alt: "un-hombre-y-una-mujer-conversan-en-la-playa",
    ...slide4
  },
  {
    alt: "foto-de-la-playa-de-cancun-en-amanecer-con-camas-de-playa-para-huespedes",
    ...slide5
  },
  {
    alt: "foto-de-lobby-del-hotel-golden-parnassus-all-inclusive-resort-and-spa",
    ...slide6
  },
] 

const Slideshow = ({
  images = [],
  counter = true,
  galleryLink = true,
  childrenItems = [],
  config = {},
  classes = {},
  isPagination = false,
  secondView = false,
  isNavigation = true,
  ...otherProps
}) => {
  const { t } = useTranslation("common", "routes");
  const slideshow = images.length ? images: DEFAULT_IMAGES;

  const [slideActive, setSlideActive] = useState(1);
  return (
    <>
      <div className="relative">
        <div className="absolute left-0 top-14 bottom-14 w-[10%] m-auto hidden md:block">
          <div className="relative h-full w-full">
            {slideActive > 1 && (
              <Image
                src={
                  slideshow[slideActive - 2].src ||
                  slideshow[slideActive - 2].image.url
                }
                fill
                style={{ objectFit: "cover" }}
                className="opacity-40"
                alt={slideshow[slideActive - 2]?.alt ||
                slideshow[slideActive - 2]?.image?.alt}
                unoptimized
              />
            )}
          </div>
        </div>
        <div className="absolute right-0 top-14 bottom-14 w-[10%] m-auto hidden md:block">
          <div className="relative h-full w-full">
            {slideActive < slideshow.length && (
              <Image
                src={
                  slideshow[slideActive].src || slideshow[slideActive].image.url
                }
                fill
                style={{ objectFit: "cover" }}
                className="opacity-40"
                alt={slideshow[slideActive]?.alt ||
                slideshow[slideActive]?.image?.alt}
                unoptimized
              />
            )}
          </div>
        </div>
        <div className="base-container base-grid p-0">
        <div className={classes?.divOverSwiper || "col-span-full md:col-start-2 md:col-span-10 mx-3"}>
            <Swiper
              loop={false}
              centeredSlides={true}
              initialSlide={config?.initialSlide || 1}
              navigation={isNavigation}
              pagination={isPagination || false}
              style={{
                "--swiper-navigation-color": "#00ABC8",
                "--swiper-navigation-size": "15px",
                "--swiper-navigation-sides-offset": "5%",
                "--swiper-theme-color": classes?.themeColor || "#007aff",
              }}
              className="slideshow-swiper"
              onSlideChange={(swiper) => {
                setSlideActive(swiper.activeIndex + 1);
              }}
              modules={[Navigation, Pagination]}
              {...config}
              {...otherProps}
            >
              {Array.isArray(slideshow) &&
                slideshow.map((slide, i) => (
                  <SwiperSlide key={i}>
                    <div className={classes?.slidesContainer || "flex w-full md:w-10/12 mx-auto justify-center"}>
                      <div className={classes?.mbImage || ""}>
                      <Image
                        src={slide.src || slide.image.url}
                        width={slide.width || slide.image.width}
                        height={slide.height || slide.image.height}
                        alt={slide?.alt || slide?.image?.alt}
                        unoptimized={true}
                      />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                {secondView && childrenItems && childrenItems[slideActive - 1]} 
                {classes?.mbDivOptional && <div className={classes?.mbDivOptional || ""}></div> }
            </Swiper>
          </div>
        </div>
      </div>
      <div className={classes?.counterContainerTop || "base-container base-grid pb-10 pt-5 bottom-slideshow"}>
        {counter && (
          <div
            className={
              classes?.counterContainer ||
              "col-span-6 md:col-start-4 md:col-span-3"
            }
          >
            <p className="text-left">
              <span
                className={
                  classes?.slideActive ||
                  "font-title font-medium text-main-blue-king text-6xl"
                }
              >
                {slideActive}
              </span>
              <span
                className={
                  classes?.separator ||
                  "text-main-blue-king text-6xl font-light"
                }
              >
                /
              </span>
              <span
                className={
                  classes?.slidesTotal ||
                  "font-title font-light text-main-blue-king text-4xl"
                }
              >
                {slideshow.length}
              </span>
            </p>
          </div>
        )}
        {galleryLink && (
          <div className="col-span-6 md:col-span-3 flex justify-end items-center">
            <Link className="hero-link text-[14px] lg:text-[16px]" href={t("routes:/gallery")} id="explora-la-galeria">
              {t("common:links.explore_gallery")}
            </Link>
          </div>
        )}
        {!secondView && childrenItems && childrenItems[slideActive - 1]}
      </div>
    </>
  );
};

export default Slideshow;
