import { apiUrl } from "@/utils/api";
import { blogDateFormat } from "@/utils/helpers";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import Text from "../atoms/Text";
import Hero from "../organisms/Hero";
import Button from "../atoms/Button";
import { useTranslation } from "next-i18next";

const INIT_BLOGS = [
  {
    id: 1,
    date: "17 DE AGOSTO 2022",
    author: "GOLDEN PARNASSUS",
    title: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    slug: "lorem-ipsum-dolor-sit-amet-consectetuer-adipiscing-elit",
    image: {
      url: "/images/home/blog-post-1.webp",
      name: "blog-1",
      width: "355",
      height: "355",
    },
  },
  {
    id: 2,
    date: "17 DE AGOSTO 2022",
    author: "GOLDEN PARNASSUS",
    title: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    slug: "lorem-ipsum-dolor-sit-amet-consectetuer-adipiscing-elit",
    thumbnail: {
      url: "/images/home/blog-post-1.webp",
      name: "blog-1",
      width: "355",
      height: "355",
    },
  },
  {
    id: 3,
    date: "17 DE AGOSTO 2022",
    author: "GOLDEN PARNASSUS",
    title: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    slug: "lorem-ipsum-dolor-sit-amet-consectetuer-adipiscing-elit",
    thumbnail: {
      url: "/images/home/blog-post-1.webp",
      name: "blog-1",
      width: "355",
      height: "355",
    },
  },
];

const BlogsHome = ({ blogs = INIT_BLOGS, locale = "es", copy = {}, styles = {}, isPagination = false, isNavigation = false, buttonBlogsOut = true}) => {

  const { t } = useTranslation();
  const [swiperVisible, setSwiperVisible] = useState(false);
  const swiperRef = useRef(null);
  let observerRef = useRef(null);

  const handleVisibilityChange = (entries) => {
    entries?.forEach((entry) => {
      if (entry?.isIntersecting) {
        setSwiperVisible(true);
        observerRef.current?.disconnect();
      }
    });
  }

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    }

    observerRef.current = observerRef.current || new IntersectionObserver(handleVisibilityChange, options);
    if (swiperRef.current) {
      observerRef.current.observe(swiperRef.current);
    }

    return () => {
      if (swiperRef.current) {
        observerRef.current.disconnect();
      }
    }

  }, [locale])

  useEffect(() => {
    setSwiperVisible(false);
  }, [locale]);

  const settings = {
    spaceBetween: 16,
    slidesPerView: 1,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

const completedBlogs = []
const required_fields = ["thumbnail", "title", "blog_category", "date", "slug",]
blogs?.forEach((blog) => {
  const incompleteFields = []
  required_fields?.forEach((field) => {
    if (!blog[field]) {
      incompleteFields?.push(field)
    }
  })
  if (incompleteFields?.length === 0) {
    completedBlogs?.push(blog)
  } else {
    console.log(`el blog con id ${blog?.id} no tiene los siguientes campos: ${incompleteFields?.join(", ")}`)
  }
})

  return (
    <section className="bg-main-blue-light py-12">
      <div className="base-container base-grid">
        <Hero 
          title={{
            text: copy?.title || "",
            className: styles?.title || "hero-title text-center mb-3 md:mb-7",
            tag: copy?.tag || "h2",
          }}
          text={{
            text: copy?.subtitle || "",
            className: styles?.subtitle || "section-subtitle text-center uppercase",
          }}
          styles={{
            heroContainer: "col-span-12",
            titleContainer: "col-span-12 order-1",
            textContainer: "col-span-12 md:col-span-10 md:col-start-2 order-0",
          }}
        />
        <div className="col-span-12" ref={swiperRef}>
          {swiperVisible && (
            <Swiper {...settings} modules={[Autoplay, Pagination, Navigation]}
            pagination={isPagination}
            navigation={isNavigation}
            style={{
              "--swiper-navigation-color": styles?.navigationColor || "",
              "--swiper-navigation-size": styles?.navigationSize || "",
              "--swiper-theme-color": styles?.themeColor || "",
            }}
          >
            {Array.isArray(blogs) &&
              completedBlogs.map((blog) => (
                <SwiperSlide key={blog.id} className="relative">
                  <div className="relative overflow-hidden w-full min-h-[246px] md:h-[350px] sm:h-full">
                    <Image
                      src={apiUrl + blog?.thumbnail?.url}
                      fill
                      className="object-cover"
                      alt="blog-post"
                    />
                  </div>
                  <div className="blog-post-home absolute bottom-0 left-0 right-0 p-4 pt-16">
                    <Text className="text-white text-center font-subtitle text-xs mb-2 uppercase">
                      {blogDateFormat(blog?.date, locale)} |
                      <span className="ml-1">
                        {blog?.blog_category?.name || ""}
                      </span>
                    </Text>
                    <Link
                      href={{
                        pathname: "blogs/[slug]",
                        query: { slug: blog.slug },
                      }}
                      className="font-title text-white hover:text-main-orange font-semibold text-center block"
                    >
                      {blog.title}
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            {styles?.accommodateDots && <div className={styles?.accommodateDots}></div>}
          </Swiper>
          )}
        </div>
        {buttonBlogsOut && (
          <div className="col-span-full mt-8">
            <Button className="base-button">
              <Link
                href={t("routes:/blogs")}
                className="hero-link text-white uppercase"
              >
                {t("common:blogs.cta.text")}
              </Link>
            </Button>
          </div>
        )
        }
      </div>
    </section>
  );
};

export default BlogsHome;
