import { apiUrl } from "@/utils/api";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import Button from "../atoms/Button";
import Text from "../atoms/Text";
import { useRouter } from "next/router";

const PromotionCard = ({ promotion = false }) => {
  const { t } = useTranslation(["common", "routes"]);
  const { locale } = useRouter();
  const gtmBookId = locale === "es" && `promotion-${promotion?.slug}-booknow` || locale === "en" && `promotion-${promotion?.localizations[0]?.slug}` || ""
  const gtmMoreId = locale === "es" && `more-promotion-${promotion?.slug}-booknow` || locale === "en" && `more-promotion-${promotion?.localizations[0]?.slug}` || ""
  return (
    promotion &&
    promotion.name &&
    promotion.description &&
    promotion.slug &&
    promotion.thumbnail.url &&
    promotion.link &&
    (
      <div className="white-65-transparent-35 md:py-6 mb-12 min-h-[800px] lg:min-h-[490px] md:min-h-[370px] relative">
        <div className="md:absolute md:inset-0 md:flex md:items-center">
          <div className="base-grid">
            <div className="md:bg-none col-start-2 col-span-10 order-2 md:order-1 md:col-span-6 md:pl-8 md:pt-6 ">
              <Text className="font-title text-[23px] lg:text-[28px] text-main-blue mb-6 text-center md:text-left">
                {promotion.name}
              </Text>
              <Text className="font-text text-main-grey mb-3 font-bold text-lg text-center md:text-left">
                {promotion.price_from}
              </Text>
              <Text className="font-text text-main-grey mb-6 text-center md:text-left">
                {promotion.description}
              </Text>
              <Button className="base-button px-0 w-full">
                <Link
                  href={promotion.link}
                  className="hero-link text-white uppercase"
                  id={gtmBookId}
                >
                  {t("common:booking.book_now")}
                </Link>
              </Button>
              <Button className="base-button px-0 bg-transparent w-full">
                <Link
                  href={{
                    pathname: `${t("routes:/deals")}/[slug]`,
                    query: { slug: promotion.slug },
                  }}
                  className="hero-link uppercase underline"
                  id={gtmMoreId}
                >
                  {t("common:booking.see_details")}
                </Link>
              </Button>
            </div>
            <div className="col-start-2 col-span-10 order-1 md:order-2 md:col-span-6 relative h-full flex items-center">
              <div className="h-[430px] md:h-[325px] flex items-center my-auto">
                <Image
                  src={apiUrl + promotion.thumbnail.url}
                  fill
                  className="object-cover block md:hidden lg:block lg:max-h-[325px] lg:min-w-[237px] xl:hidden my-auto"
                  alt={promotion?.thumbnail_alt || "promotions-thumbnail"}
                />
                <Image
                  src={apiUrl + promotion.thumbnail.url}
                  width={1120}
                  height={800}
                  className="object-cover hidden md:block lg:hidden xl:block my-auto"
                  alt={promotion?.thumbnail_alt || "promotions-thumbnail"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default PromotionCard;
