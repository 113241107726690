import "moment/locale/es";
import moment from "moment/moment";
import { useEffect } from "react";
// const months = {
//   es: [
//     "Enero",
//     "Febrero",
//     "Marzo",
//     "Abril",
//     "Mayo",
//     "Junio",
//     "Julio",
//     "Agosto",
//     "Septiembre",
//     "Octubre",
//     "Noviembre",
//     "Diciembre",
//   ],
//   en: [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "Octuber",
//     "November",
//     "December",
//   ],
// };

export const blogDateFormat = (date, locale) => {
  return (
    (!date && "") ||
    (date &&
      locale === "es" &&
      moment(date).locale(locale).format("DD MMMM YYYY")) ||
    (locale === "en" && moment(date).locale(locale).format("MMMM DD[,] YYYY"))
  );
};

export const isSlugComplete = (...fields) => {
  return fields?.every((field) => field ?? false)
}

export const getCompleteCollection = ({ type = "", list = [], requiredFields }) => {
  const completeCollection = []
  list?.forEach((item) => {
    const missingFields = []
    requiredFields?.forEach((field) => {
      if (!item[field]) {
        missingFields.push(field)
      }
    });
    if(missingFields?.length === 0){
      completeCollection.push(item)
    } else {
      console.log(`el/la ${type} con id ${item?.id} no tiene los siguientes campos: [${missingFields.join(", ")}]`)
    }
  })
  return completeCollection
}

export const addConditionalNoIndexTag = () => {
  useEffect(() => {
    let meta;
    if (window.location.search.length > 0) {
      meta = document.createElement("meta");
      meta.name = "robots";
      meta.content = "noindex";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
    return () => {
      if(meta) { document.getElementsByTagName("head")[0].removeChild(meta); }
    };
  }, []);
};