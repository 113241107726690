import Image from "next/image";
import classNames from "classnames";
import Hero from "../organisms/Hero";
import Paragraphs from "../molecules/Paragraphs";
const Welcome = (props) => {
  const topParagraphs =
    props.topParagraphs && Array.isArray(props.topParagraphs)
      ? props.topParagraphs
      : false;
  const paragraphs =
    props.paragraphs && Array.isArray(props.paragraphs)
      ? props.paragraphs
      : false;
  const styles = {
    ...props?.styles,
    heroContainer:
      props?.styles?.heroContainer ||
      "col-span-12 md:col-start-2 md:col-span-10  ",
    welcomeContainer: props?.styles?.welcomeContainer || " py-12",
    baseContainer: props?.styles?.baseContainer || "",
    topParagraphs: props?.styles?.topParagraphs || " grid gap-5 md:gap-8",
    topParagraphsContainer:
      props?.styles?.topParagraphsContainer ||
      "col-span-12 sm:col-start-2 sm:col-span-10 lg:col-start-3 lg:col-span-8 ",
    paragraphsContainer:
      props?.styles?.paragraphsContainer ||
      "col-span-12 sm:col-start-2 sm:col-span-10 md:col-start-2 md:col-span-5  lg:col-start-3 lg:col-span-4",
    paragraphs: props?.styles?.paragraphs || " ",
    imageContainer:
      props?.styles?.imageContainer ||
      "col-span-12  md:col-span-5 lg:col-start-8 lg:col-span-4 z-10 px-6 md:px-0",
    image: props?.styles?.image || "",
    bottomImageContainer:
      props?.styles?.bottomImageContainer || " col-span-12 -mr-3 -ml-3 md:mx-0",
    bottomImage: props?.styles?.bottomImage || "w-full object-cover  ",
  };
  return (
    <section className={classNames(styles.welcomeContainer)}>
      <div
        className={classNames(
          "base-container base-grid",
          styles?.baseContainer
        )}
      >
        {props?.title || props?.text ? (
          <Hero title={props.title} text={props.text} secondTitle={props.secondTitle || false} styles={styles} />
        ) : (
          ""
        )}
        <div className={classNames(styles?.topParagraphsContainer)}>
          {(topParagraphs && (
            <Paragraphs
              items={topParagraphs}
              classes={classNames(styles?.topParagraphs)}
            />
          )) ||
            ""}
        </div>
        <div className={classNames(styles?.paragraphsContainer)}>
          {(paragraphs && (
            <Paragraphs items={paragraphs} classes={styles?.paragraphs} />
          )) ||
            ""}
        </div>
        <div className={classNames(styles?.imageContainer)}>
          {(props?.image && (
            <Image
              src={props?.image}
              alt={props?.image?.alt}
              className={classNames(styles?.image)}
            />
          )) ||
            ""}
        </div>
        <div className={classNames(styles?.bottomImageContainer)}>
          {(props?.bottomImage && (
            <Image
              src={props?.bottomImage}
              alt="welcome-cover"
              className={classNames(styles?.bottomImage)}
            />
          )) ||
            ""}
        </div>
      </div>
    </section>
  );
};

export default Welcome;
